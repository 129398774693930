/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import useForm, {
  STATUS_ERROR,
  STATUS_SENDING,
} from 'hooks/useForm';
import useAotuAPI from 'hooks/useAotuAPI';
import GreenCheck from 'svg/green-check.svg';
import Container from 'components/Container';
import Button from 'components/Button';
import './DownloadPage.module.css';

const DOCS_URL = '/docs/getting_started/';

function getCustomFieldIds(customFieldIds, fields) {
  const {
    locale,
    name,
    role,
    roleOther,
  } = fields;

  return {
    [customFieldIds.locale]: locale,
    [customFieldIds.name]: name,
    [customFieldIds.role]: role,
    [customFieldIds.roleOther]: roleOther,
  };
}

const subscribeFormHandler = (aotuAPI, customFieldIds) => (body) => {
  const { email, list_id, ...custom_fields } = body;
  const customFields = getCustomFieldIds(customFieldIds, custom_fields);

  return aotuAPI.subscribe(list_id, email, customFields)
    .then(() => {
      // Redirect to docs after user is subscribed
      window.location = DOCS_URL;
    });
};

function DownloadForm({
  intl,
  customFieldIds,
  listId,
}) {
  const aotuAPI = useAotuAPI();

  const [
    handleChange,
    handleSubmit,
    {
      name = '',
      email = '',
      role = '',
      roleOther = '',
    },
    status,
  ] = useForm(
    subscribeFormHandler(aotuAPI, customFieldIds),
    { list_id: listId, locale: intl.locale },
  );

  return (
    <form onSubmit={handleSubmit} styleName="form">
      <h5>
        {intl.formatMessage({ id: 'download.form-cta' })}
      </h5>

      <div styleName="role-select">
        <span>
          {`${intl.formatMessage({ id: 'download.form.role-label' })}:`}
        </span>

        <div>
          <label htmlFor="form-role-1">
            <input
              type="radio"
              name="role"
              id="form-role-1"
              value="system_integrator"
              checked={role === 'system_integrator'}
              onChange={handleChange}
              required
            />

            {intl.formatMessage({ id: 'download.form.integrator' })}
          </label>
        </div>

        <div>
          <label htmlFor="form-role-2">
            <input
              type="radio"
              name="role"
              id="form-role-2"
              value="developer"
              checked={role === 'developer'}
              onChange={handleChange}
            />

            {intl.formatMessage({ id: 'download.form.developer' })}
          </label>
        </div>

        <div>
          <label htmlFor="form-role-3">
            <input
              type="radio"
              name="role"
              id="form-role-3"
              value="oem_vendor"
              checked={role === 'oem_vendor'}
              onChange={handleChange}
            />

            {intl.formatMessage({ id: 'download.form.oem_vender' })}
          </label>
        </div>

        <div>
          <label htmlFor="form-role-4">
            <input
              type="radio"
              name="role"
              id="form-role-4"
              value="other"
              checked={role === 'other'}
              onChange={handleChange}
            />

            {intl.formatMessage({ id: 'download.form.other' })}
          </label>

          <div styleName="hidden-field" data-visible={role === 'other'}>
            <label htmlFor="form-role-other">
              <input
                type="text"
                id="form-role-other"
                name="roleOther"
                placeholder={`${intl.formatMessage({ id: 'download.form.other-placeholder' })}*`}
                onChange={handleChange}
                value={roleOther}
                required={role === 'other'}
                maxLength={255}
              />
            </label>
          </div>
        </div>
      </div>

      <div styleName="input-row">
        <div>
          <label htmlFor="name">
            <input
              type="text"
              id="name"
              name="name"
              placeholder={`${intl.formatMessage({ id: 'contact.form.name' })}*`}
              onChange={handleChange}
              value={name}
              maxLength={255}
              required
            />
          </label>
        </div>

        <div>
          <label htmlFor="email">
            <input
              type="email"
              id="email"
              name="email"
              placeholder={`${intl.formatMessage({ id: 'contact.form.email' })}*`}
              onChange={handleChange}
              value={email}
              maxLength={255}
              required
            />
          </label>
        </div>
      </div>

      {status === STATUS_ERROR && (
        <div styleName="status-message">
          {intl.formatMessage({ id: 'contact.send-failure' })}
          {' '}
          <a href="mailto:contact@aotu.ai">
            contact@aotu.ai
          </a>
          .
        </div>
      )}

      <Button type="submit" disabled={status === STATUS_SENDING}>
        {status === STATUS_SENDING ? (
          intl.formatMessage({ id: 'contact.form.sending' })
        ) : (
          intl.formatMessage({ id: 'download.download-signup' })
        )}
      </Button>

      <a href={DOCS_URL} styleName="skip">
        {intl.formatMessage({ id: 'download.download-skip' })}
      </a>
    </form>
  );
}

DownloadForm.propTypes = {
  intl: intlShape.isRequired,
  customFieldIds: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  listId: PropTypes.string,
};

DownloadForm.defaultProps = {
  listId: '',
};

function Features({ intl }) {
  return (
    <div styleName="features">
      <div>
        <GreenCheck />

        <span>
          {intl.formatMessage({ id: 'download.free' })}
        </span>
      </div>

      <div>
        <GreenCheck />

        <span>
          {intl.formatMessage({ id: 'download.streams' })}
        </span>
      </div>

      <div>
        <GreenCheck />

        <span>
          {intl.formatMessage({ id: 'download.license' })}
        </span>
      </div>
    </div>
  );
}

Features.propTypes = {
  intl: intlShape.isRequired,
};

const DownloadPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query EmailListDownload {
      site {
        siteMetadata {
          emailSubscribeListDownload
          emailCustomFieldIds {
            locale
            name
            role
            roleOther
          }
        }
      }
    }
  `);
  const {
    emailSubscribeListDownload,
    emailCustomFieldIds,
  } = data.site.siteMetadata;

  return (
    <div>
      <Container>
        <h4 styleName="page-title">
          {intl.formatMessage({ id: 'download.title' })}
        </h4>

        <Features intl={intl} />

        <DownloadForm
          intl={intl}
          listId={emailSubscribeListDownload}
          customFieldIds={emailCustomFieldIds}
        />
      </Container>
    </div>
  );
};

DownloadPage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(DownloadPage);
