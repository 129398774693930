import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import DownloadPage from 'components/pages/DownloadPage';

const Download = () => (
  <Layout>
    <Helmet title="Get BrainFrame" />

    <DownloadPage />
  </Layout>
);

export default Download;
